import React from "react";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";
import Typewriter from "typewriter-effect";
import { scrollDuration } from "../../../config/commonConfig";

const StandardMenuImgBgIntro = () => {
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />
        {/* <div
          className="hero-bg parallax"
          style={{ backgroundImage: 'url("images/intro-bg.jpg")' }}
        /> */}
        <Parallax bgImage="images/coding.jpg" bgImageAlt="Intro" strength={200}>
          <div className="hero-content d-flex fullscreen-with-header py-5">
            <div className="container my-auto py-4">
              <div className="row">
                <div className="col-lg-12 text-center order-1 order-lg-0">
                  <h1 className="text-8 text-white fw-400 mb-0 text-uppercase">
                    Hi, I'm a Software
                  </h1>
                  <h2 className="text-23 text-white fw-600 text-uppercase mb-0 ms-n1">
                    <Typewriter
                      options={{
                        strings: ["Engineer", "Developer", "Designer"],
                        autoStart: true,
                        loop: true,
                      }}
                    />{" "}
                  </h2>
                  <p className="text-5 text-light">based in Dallas, Texas.</p>
                  <Link
                    className="btn btn-primary fw-600 rounded-0 smooth-scroll mt-3"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    to="portfolio"
                  >
                    View Projects
                  </Link>{" "}
                </div>
              </div>
            </div>
            <Link
              className="scroll-down-arrow text-light smooth-scroll"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer" }}
              to="about"
            >
              <span className="animated">
                <i className="fas fa-arrow-down" />
              </span>
            </Link>{" "}
          </div>
        </Parallax>
      </div>
    </section>
  );
};

export default StandardMenuImgBgIntro;
