import React from "react";
import resumeFile from "../documents/resume.pdf";
import Image from "react-bootstrap/Image";
import "bootstrap/dist/css/bootstrap.min.css";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2023 - 2026",
      title: "Problem-Solving & Critical Thinking",
      place: "Western Governor University",
      desc: "Throughout my software development career, I've meticulously honed my problem-solving and critical thinking abilities. My approach emphasizes in-depth analysis, precise debugging, and collaborative brainstorming. This method ensures I deliver robust, scalable solutions, and consistently demonstrate resilience and adaptability, even under the most challenging circumstances.",
    },
    {
      yearRange: "2023 - 2027",
      title: "Adaptability",
      place: "University of Washington",
      desc: "In my software engineering journey, I've refined my adaptability skills to thrive in fast-paced environments by embracing new challenges and rapidly integrating emerging technologies. My flexible mindset allows me to adjust to changing project requirements while maintaining high standards, and I excel in dynamic team settings, leveraging adaptability to deliver innovative solutions.",
    },
    {
      yearRange: "2019 - 2020",
      title: "Result Driven Approach",
      place: "University of Washington",
      desc: "In my professional endeavors, I consistently prioritize a result-driven approach, ensuring every project meets its objectives efficiently. By melding strategic planning with actionable execution, I've consistently delivered outcomes that exceed expectations. My commitment to tangible results has fostered enhanced productivity, streamlined processes, and significant value generation across all tasks.",
    },
    {
      yearRange: "2019 - 2020",
      title: "Mentorship & Team Coaching",
      place: "University of Washington",
      desc: "I have always been committed to mentoring and coaching, focusing on providing individualized guidance, facilitating skill development workshops, and offering constructive feedback. I foster continuous learning, helping team members realize their potential and excel in their roles, ultimately cultivating a collaborative, innovative, high-performing team environment.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2023 - current",
      title: "Full Stack Engineer",
      place: "Lockheed Martin",
      desc: "Full Stack Engineer for the F-35 program, driving next-generation solutions through Agile methodologies, DevOps practices, and advanced microservices architecture. Specialized in React (frontend), Java(backend), CI/CD (GitLab), REST APIs, SQL, and OpenShift deployment",
    },
    {
      yearRange: "2020 - current",
      title: "Full Stack Engineer - AWS Developer",
      place: "Freelance",
      desc: "Developed cloud-native applications using React, Node, and AWS serverless technologies. Skilled in Infrastructure as Code tools: AWS SAM, CDK, and Amplify CLI. Specialized in API Gateway, Lambda, DynamoDB, and AppSync with GraphQL integrations.",
    },
    {
      yearRange: "2022 - 2023",
      title: "Software Developer - Front End",
      place: "Pacific Science & Engineering",
      desc: "Designed applications using React, Typescript and Node.js. Cooperated with UI/UX Designers and Human Factors Engineers to devise and implement scientifically informed solutions for prototype applications. Primarily supported agile software projects for U.S defense agencies.",
    },
    {
      yearRange: "2015 - 2022",
      title: "Systems Support Engineer",
      place: "Amazon",
      desc: "Developed technical documentation and blueprints for software projects, emphasizing security. Built scalable applications using React JS and Node, leveraging AWS services like DynamoDB, API Gateway, and Lambda. Experienced in AWS microservices; mentored colleagues in cloud best practices.",
    },
  ];

  const skills = [
    {
      name: "React JS",
      percent: 95,
      icon: "fab fa-react",
    },
    {
      name: "Typescript",
      percent: 95,
      icon: "fas fa-code",
    },
    {
      name: "Node JS / Python / Java",
      percent: 95,
      icon: "fas fa-code-branch",
    },
    {
      name: "AWS",
      percent: 95,
      icon: "fab fa-aws",
    },
    {
      name: "Redux Toolkit / Zustand / Jotai",
      percent: 95,
      icon: "far fa-file-code",
    },
    {
      name: "Material UI / Ant Design / Chakra UI / Bootstrap",
      percent: 95,
      icon: "fas fa-laptop-code",
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Experience</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Experience
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>

          {/* My Education */}
          {/* <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div> */}
          <div className="col-lg-6 wow fadeInUp">
            {/* <Image src="images/dev2.jpg" fluid/> */}
            <h2 className="text-7 fw-600 mb-4 pb-2">My Skills</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    {/* <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p> */}
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">
          Tech Stack
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  {/* <span className="float-end">{skill.percent}%</span> */}
                  <span className="float-end">
                    <i className={skill.icon} />
                  </span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        {/* <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p> */}
      </div>
    </section>
  );
};

export default Resume;
